import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col py-0" }
const _hoisted_3 = { class: "subtitle-1" }
const _hoisted_4 = { class: "col-auto py-0" }
const _hoisted_5 = { class: "mb-6" }
const _hoisted_6 = { class: "mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_dialog = _resolveComponent("app-dialog")!

  return (_openBlock(), _createBlock(_component_app_dialog, {
    open: _ctx.open,
    width: 450,
    onDialogClosed: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("button.edit")) + " " + _toDisplayString(_ctx.t("dictionary.kindTypeResource")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_button, {
            icon: "",
            onClick: _ctx.closeModal,
            class: "ma-n3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_icon, {
                icon: "cross",
                size: 16
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.edit && _ctx.edit(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_app_text_field, {
            name: "name",
            modelValue: _ctx.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            label: _ctx.t("dictionary.name"),
            placeholder: _ctx.t("form.placeholder.editName"),
            outlined: "",
            errorMessage: _ctx.nameError
          }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_app_text_field, {
            name: "comment",
            modelValue: _ctx.comment,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.comment) = $event)),
            label: _ctx.t("dictionary.comment"),
            placeholder: _ctx.t("form.placeholder.editComment"),
            outlined: "",
            errorMessage: _ctx.commentError
          }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"])
        ]),
        _createVNode(_component_app_button, {
          block: "",
          large: "",
          color: "bg-blue text-white",
          onClick: _ctx.edit,
          loading: _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("button.save")), 1)
          ]),
          _: 1
        }, 8, ["onClick", "loading"])
      ], 32)
    ]),
    _: 1
  }, 8, ["open", "onDialogClosed"]))
}