
import {defineComponent, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {KindTypeResources} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["dialog-closed", "item-added"],
	props: {
		open: {
			type: Boolean,
			default: false
		}
	},
	setup(_, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, values} = useForm();

		const { value: name, errorMessage: nameError } = useField<string>('name', 'required');
		const { value: comment, errorMessage: commentError } = useField<string>('comment');

		const isLoading = ref(false);
		const service = injectOrThrow<KindTypeResources.KindTypeResourceService>('KindTypeResources.KindTypeResourceService');

		const add = handleSubmit(async () => {
			isLoading.value = true;

			try {
				await service.add({
					name: values.name,
					comment: values.comment,
					id_enterprise: store.state.globals.enterpriseId
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.added", { name: ucFirst(t("dictionary.kindTypeResource")) })});
				emit("item-added");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = () => {
			emit('dialog-closed');
			handleReset();
		}

		return {
			t,
			name, nameError,
			comment, commentError,
			isLoading, add, closeModal
		}
	}
})
